import * as React from "react"
import PageTemplate from "../components/layout/page-template"

const Ele = () => {
  return (
    <PageTemplate pageTitle="Terms of Use" pageSubtitle="April 11, 2024">
      <section>
        <h2>Introduction</h2>
        <p>
          MYXENO Investment Management Limited (“XENO”, “we”, “us” and/or “our”)
          operates an Automated Investment Management Platform (the “Platform”).
          The Platform is available through our website, mobile application, and
          USSD. By continuing to use the Platform, you are agreeing to comply
          with and be bound by these terms of use. Please do not use our
          Platform if you do not agree with any part of these Terms of Use.
          Please note that these Terms of Use should be read together with our
          Privacy Policy and Investment Management Agreement.{" "}
        </p>
      </section>
      <section>
        <h2>Important Legal Information</h2>
        <p>
          Please read these Terms of Use carefully. XENO owns and operates the
          investment management portion of this website (“Site”). By registering
          for, logging into, visiting and/or otherwise using the Platform in any
          way, you indicate that you understand and intend these Terms of Use to
          be the legal equivalent of a signed, written contract, and you accept
          the terms of use as set forth herein and agree to be legally bound by
          them. Please do not access this website or any pages thereof if you do
          not agree to these Terms of Use.
        </p>
      </section>
      <section>
        <h2>Reservation of Rights</h2>
        <p>
          XENO reserves the right, at our discretion, to modify, change, add, or
          remove sections of these Terms of Use at any time. Your continued use
          of the Platform following reasonable notice of such modifications will
          be conclusively deemed acceptance of any changes to these Terms of
          Use. You agree that notice of changes to these Terms of Use posted on
          the Platform constitutes reasonable and sufficient notice. At all
          times, you agree to be bound by the latest version of the Terms of Use
          and all applicable laws.
        </p>
      </section>
      <section>
        <h2>Definitions</h2>
        <p>
          Throughout this agreement, a Visitor refers to a person who uses our
          Platform for purpose of gaining information. On the other hand, a
          client is a person who signs our Investment Management Agreement (the
          “Agreement”) and sets up an investment account managed by XENO on our
          Platform. The term “you” refers to the Visitor or Client of our
          Automated Investment Service (the “Service”). This Terms of Use and
          where applicable Agreement collectively governs your use or assessment
          of our Platform.
        </p>
      </section>
      <section>
        <h2>Intended Use of Content</h2>
        <p>
          Our Platform is exclusively intended for private use by natural
          persons with places of residence in countries, which fulfil the
          statutory and contractual requirements for making use of the service
          offered by XENO. The data, information, or other content of the
          Platform (altogether “Content”) may not comply with regulatory and
          other statutory requirements in jurisdictions outside Uganda. The
          Content on this Platform is available for informational purposes only.
          The posting of Content and access to this Platform does not
          constitute, either explicitly or implicitly, any provision of services
          or products of XENO or any of its respective affiliates.
        </p>
      </section>
      <section>
        <h2>No Offer</h2>
        <p>
          There is nothing contained herein that constitutes an offer,
          solicitation or recommendation regarding any investment management
          product or service, or the offer to sell or the solicitation of an
          offer to buy any security; nor shall any such services be provided, or
          securities are offered or sold, in any jurisdiction in which such an
          offer, solicitation, provision or sale would be unlawful. All Platform
          Content is subject to applicable statutes and regulations. Any
          potential investor should satisfy himself/herself that becoming a XENO
          Client is permissible under the rules and regulations of his or her
          domicile. The Platform is not directed to any individual in any
          jurisdiction where (by reason of that person's nationality, residence
          or otherwise) the availability of the Platform is expressly prohibited
          by law. Individuals in respect of whom such prohibitions apply must
          not access the Platform. The information provided on the Platform is
          for informational purposes only and investors should therefore decide
          for themselves whether a particular service or product is suitable for
          their investment needs otherwise, they should seek such professional
          advice for their particular situation. Please be sure to refer to
          specific offering documents for further information on each product or
          service. Certain statements contained herein may be statements of
          future expectations and other forward-looking statements that are
          based on our current views and assumptions and involve both known and
          unknown risks and uncertainties that may cause the actual results,
          performance, or events to materially differ from those expressed or
          implied in such statements. Furthermore, to statements which are
          forward-looking by reason of context, the words “may, will, should,
          expects, plans, intends, anticipates, believes, estimates, predicts,
          potential, or continue” and similar expressions identify
          forward-looking statements. The matters discussed herein may also
          involve risks and uncertainties described from time to time in the
          respective XENO filings with Uganda’s Capital Markets Authority. The
          company assumes no obligation to update any forward-looking
          information contained herein.
        </p>
      </section>
      <section>
        <h2>Information you provide</h2>
        <p>
          In order to provide you with products and financial services that are
          relevant to your requests, we must have accurate information from you.
          So, in providing information to us, you confirm that all data you have
          given is accurate and if you wish to correct or update the information
          you give us, you can do so by contacting us at kenya@myxeno.com . XENO
          may terminate any services or products offered to you or others based
          upon your intentionally providing materially false information or
          acting under a false identity that you have previously given us.
        </p>
      </section>
      <section>
        <h2>No Advice</h2>
        <p>
          The Content does not constitute investment, legal or tax advice. In
          particular, the Content on Financial Instruments is not to be
          understood as individual investment advice or an investment
          recommendation. Financial Instruments and financial services are
          subject to various risks. Thus, it may be necessary to consult a
          professional adviser on financial, tax and/or legal matters. It shall
          be your responsibility to ensure that any products, services, or
          information made available through this website are suited to your
          specific needs. Different types of investments involve different
          degrees of risk, and there is no guarantee that the future performance
          of any specific investment or investment strategy (including those
          recommended or undertaken by XENO), will be profitable at all or equal
          to any historical performance levels. You may get less than what you
          put in. Not all investments on the Platform may be suitable for all
          investors. Therefore, investors should make their own investment
          decisions based on their financial objectives and financial resources
          and should obtain independent investment and tax advice before
          deciding to invest with XENO. In addition, the scenarios discussed on
          the Platform have been selected only as examples to demonstrate the
          possible performance of investments in different investment
          portfolios. The selected investments do not represent XENO’s entire
          investment universe with regard to a particular asset class,
          geographic region, and/or a particular investment account. The
          selected scenarios may be based on ongoing investments of certain
          investment portfolios managed by XENO, and are not intended to be, and
          should not be construed as, investment advice or a recommendation to
          purchase or sell any particular security. Investment scenarios or
          other investments discussed on the Platform ultimately may generate
          positive returns, and other investments made in these asset classes or
          geographic regions, but not discussed on the Platform, may generate
          negative returns, or vice versa. It should not be assumed that
          investments made for any particular investment account will match the
          performance or character of the investments discussed on the Platform,
          or that the returns of any accounts managed by XENO will equal the
          performance of the investments discussed on the Platform. Investors
          may experience materially different results.
        </p>
      </section>
      <section>
        <h2>Exclusion of warranty </h2>
        <p>
          The Content is presented merely to explain our investment management
          service. Unless expressly stipulated otherwise by mandatory provisions
          of law and/or contractual provisions, XENO assumes no liability for
          the completeness, timeliness and/or correctness of the Content. You
          also acknowledge that the platform may contain errors, bugs, and other
          problems that could cause failures in our systems. As a result, the
          Platform and the content are provided “as is” and “as available”
          without any warranties of any kind, including that the Platform or
          Content will function without error or that the Platform, its servers,
          or the content are free of computer viruses, similar contamination, or
          malware. We disclaim all warranties, including, but not limited to,
          warranties of title, merchantability, non-infringement of third
          parties rights, and fitness for a particular purpose and any
          warranties arising from a course of dealing, course of performance, or
          usage of trade. Certain jurisdictions do not allow the exclusion of
          some warranties. Therefore, some of the above limitations on
          warranties in this section may not apply to you. No advice or
          information, whether oral or written, obtained by you from XENO, shall
          create any warranty not expressly stated in this Agreement. If you
          choose to rely on this information, you do so solely at your own risk.
          XENO may interrupt, cease and/or amend the way the Platform functions
          or the access to the Platform in whole or in part at any time without
          notice.
        </p>
      </section>
      <section>
        <h2>Restriction of Liability</h2>
        <p>
          In no event shall XENO or any of its officers, directors, employees,
          affiliates, or agents be liable to you for any damages whatsoever,
          including without limitation indirect, incidental, special, punitive,
          or consequential damages, arising out of or in connection with your
          use of XENO, content and/or user information, including but not
          limited to the quality, accuracy, or utility of the information
          provided as part of or through XENO or for any investment decisions
          made on the basis of such information, whether the damages are
          foreseeable and whether or not XENO has been advised of the
          possibility of such damages. The foregoing limitation of liability
          shall apply to the fullest extent permitted by law in the relevant
          jurisdiction and no event shall XENO's cumulative liability to you go
          beyond the total fees that you have paid to us within the three (3)
          months immediately preceding the events giving rise to the claim.{" "}
        </p>
      </section>
      <section>
        <h2>Third-Party Links</h2>
        <p>
          Following certain links within our Platform or certain other Platforms
          that are linked to our Platform may direct you to other websites, or
          display information from other websites, which may not be run by XENO.
          Such linked websites may contain their Terms of Use, confidentiality
          provisions, privacy provisions, or other provisions that differ from
          the Terms of Use applicable to our Platform. The links to other
          websites are provided merely for the convenience of the visitors and
          clients. However, a link to any website is not an endorsement by XENO
          of any kind of service or website, its content, or its sponsoring
          company. XENO assumes no responsibility or liability whatsoever for
          the content, accuracy, reliability, or opinions expressed in an
          external website (a “linked website”) and such linked websites are not
          monitored, investigated, or checked for accuracy or completeness by
          XENO. It is your sole responsibility to assess the accuracy,
          reliability, timeliness, and completeness of any information availed
          on the linked website. All products, services and content obtained
          from a linked website are provided “as is” without warranty of any
          kind, express or implied, including, but not limited to, implied
          warranties of merch.
        </p>
      </section>
      <section>
        <h2>Copyright and other Property Rights</h2>
        <p>
          This Platform (including the software and files), the computers
          hosting this Platform, and content provided on this Platform,
          including any text, music and sound, graphics, images, photographs,
          audio and video, footage, animations, podcasts, analyses, studies,
          reports, and downloads (all such content collectively, the “Content”)
          are the exclusive property of the XENO Corporation and are protected
          by law, including the United States copyright, database, trade secret,
          and trademark law, as well as another state, national, and
          international laws and regulations. The XENO reserve all rights
          concerning its proprietary information or material on this Platform
          and will enforce such rights to the full extent of applicable
          copyright and trademark law. Except as expressly provided in these
          Terms of Use, XENO does not grant any express or implied rights to
          you. All rights in the pages and Content are owned by the XENO and
          their respective licensors. You expressly agree not to sell,
          distribute, publish, broadcast, circulate or commercially exploit Site
          Content in any manner without the express written consent of XENO. Any
          downloading or otherwise copying from this Platform will not transfer
          title to any software or material to you. Unless otherwise noted, XENO
          and other brands featured on the Platform constitute our trade/service
          marks. You agree not to directly or indirectly: attempt to register,
          challenge or contest the validity of the respective XENO’s ownership
          of, such copyrights, trademarks, or any other intellectual property of
          any XENO, or assist any third party in doing so.
        </p>
      </section>
      <section>
        <h2>Termination</h2>
        <p>
          You may request us to terminate your Client Account at any time and
          for any reason by sending an email to kenya@myxeno.com. We may
          terminate or suspend your access to XENO, in our sole discretion, at
          any time for any reason without notice to you. Moreover, if we
          believe, in our sole discretion, that a violation of these Terms of
          Use has occurred, we may take any other corrective action we consider
          appropriate. We reserve the right to investigate suspected violations
          of these Terms of Use. We may seek to gather information from a user
          who is suspected of violating these Terms of Use (or from any other
          user) and you agree to provide us with such information. We will fully
          cooperate with any law enforcement authorities or court order
          requesting or directing us to disclose the identity of anyone posting,
          publishing, or otherwise making available any User information,
          emails, or other materials that are believed to violate these Terms of
          Use. Any suspension, termination, or cancellation shall not affect
          your obligations to XENO under these Terms of Use (including but not
          limited to ownership, indemnification, and limitation of liability),
          which by their sense and context are intended to survive such
          suspension, termination, or cancellation.
        </p>
      </section>
      <section>
        <h2>Username and Password</h2>
        <p>
          You must treat your username and password as confidential. If you know
          or suspect that anyone other than you know your username and password,
          you must promptly contact us at kenya@myxeno.com. You will be
          responsible for any losses you incur as a result of your failure to
          keep your username and password secure.
        </p>
      </section>
      <section>
        <h2>Miscellaneous</h2>
        <p>
          We may amend these Terms of Use at any time without prior notice. If
          at any time, any provision of the Terms of Use is or becomes illegal,
          invalid, or unenforceable in any respect under the law of any
          jurisdiction, neither the legality, validity, or enforceability of any
          other provision of the Terms of Use nor the legality, validity, or
          enforceability under the law of any other jurisdiction shall in any
          way be affected or impaired. Your use of the Platform and any dispute
          arising out of such use are subject to the laws of Uganda.
        </p>
      </section>
    </PageTemplate>
  )
}
export default Ele
